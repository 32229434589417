// Types
import mixins from"vue-typed-mixins";// Mixins
import{WithRefs}from"../../utils/withRefs";const refs=WithRefs();// @ts-ignore
import{directive}from"v-click-outside";// @ts-ignore
import{FadeTransition}from"vue2-transitions";// Event used to close one modal if the other one with the same group prop was opened
const HIDE_MENU_EVENT="hide-menu",CmsAltMenu=mixins(refs).extend({name:"CmsAltMenu",directives:{"click-outside":directive},props:{fixed:{type:Boolean,default:!1},disabled:{type:Boolean,default:!1},clickOutsideWhitelist:{type:String,default:""},closeOnContentClick:{type:Boolean,default:!1},tag:{type:String,default:"div"},disableClickOutside:{type:Boolean,default:!1},group:{type:String,default:""},// By default, v-show is being use
useVIfDirective:{type:Boolean,default:!1},// Used to identify list
// and prevent clicking outside while list item is opened
isEditorList:{type:Boolean,default:!1}},data(){return{opener:null,content:[],isOpened:!1,position:{}}},watch:{$isInEditMode(a){a||this.close()},isOpened(a){if(this.isEditorList){const b=document.getElementById("cms-main-layout");// @ts-ignore
b.style.pointerEvents=a?"none":"auto"}}},async mounted(){this.content=this.$slots.default||[]},beforeDestroy(){this.attachContent(),this.$nuxt.$off(HIDE_MENU_EVENT)},methods:{// Close one modal if the other one with the same group prop was opened
handleHideMenuEvent({group:a,caller:b}){this.isOpened&&b!==this&&a===this.group&&this.close()},activatorClicked(){var a,b;null===(a=this.opener)||void 0===a||null===(b=a.elm)||void 0===b?void 0:b.removeEventListener("click",this.activatorClicked);this.disabled||(this.$nuxt.$emit(HIDE_MENU_EVENT,{group:this.group,caller:this}),this.$nuxt.$on(HIDE_MENU_EVENT,this.handleHideMenuEvent),this.isOpened=!0,this.position=this.calculatePosition(),this.$nextTick(()=>{this.detachContent()}))},close(){if(this.isOpened=!1,this.isEditorList){const a=document.getElementById("cms-main-layout");// @ts-ignore
a.style.pointerEvents="auto"}// If it successfully closed, return true
return this.$nuxt.$off(HIDE_MENU_EVENT),!this.isOpened},async emitStatus(){await this.$nextTick(),this.isOpened?this.$emit("opened"):this.$emit("closed")},calculatePosition(){var a,b,c;const d=null===(a=this.opener)||void 0===a?void 0:a.elm;if(!d)return;const e={downwards:1,upwards:2,toTheRight:3,toTheLeft:4};Object.freeze(e);let f=e.downwards,g=e.toTheRight;const h=(null===(b=this.$refs.content)||void 0===b?void 0:b.offsetHeight)||0,i=(null===(c=this.$refs.content)||void 0===c?void 0:c.offsetWidth)||0,j=Math.max(document.documentElement.clientHeight||0,window.innerHeight||0),k=Math.max(document.documentElement.clientWidth||0,window.innerWidth||0),l=d.getBoundingClientRect(),m=window.pageXOffset||document.documentElement.scrollLeft,n=window.pageYOffset||document.documentElement.scrollTop,o=l.width,p=l.height,q=k-l.left-o;l.top>h&&(f=e.upwards),q<i&&(g=e.toTheLeft);let r=g===e.toTheRight?this.fixed?l.left+m:l.left:this.fixed?l.left+o-i:Math.min(l.left+o,k-33)-i;let s;return s=f===e.downwards?this.fixed?l.top+p:l.top+n+p+2:this.fixed?l.top-h:l.top+n-h-2,{position:this.fixed?"fixed":"absolute",top:`${s}px`,left:`${0<r?r:0}px`,minWidth:`${o}px`}},genOpener(){var a,b,c;const d=(null===(a=(b=this.$scopedSlots).opener)||void 0===a||null===(c=a.call(b,{isActive:this.isOpened}))||void 0===c?void 0:c[0])||null;return this.$nextTick(()=>{var a;null===d||void 0===d||null===(a=d.elm)||void 0===a?void 0:a.addEventListener("click",this.activatorClicked)}),this.opener=d,d},genContent(){var a,b;const c=Object.values({tag:"div",data:{ref:"content",style:this.position,class:"z-core-menu",directives:[// If we are using v-if, dont add v-show directive
!this.useVIfDirective&&{name:"show",value:this.isOpened},{name:"click-outside",value:{handler:this.handleClickOutside,middleware:this.checkIfClickedActivator}}].filter(Boolean),on:{click:()=>this.closeOnContentClick&&this.close()}},children:null===(a=(b=this.$scopedSlots).default)||void 0===a?void 0:a.call(b,{close:()=>this.close(),reposition:()=>this.position=this.calculatePosition()})}),d=[this.$createElement(...c)];//@ts-ignore
return this.$createElement(FadeTransition,{props:{duration:150},on:{"before-enter":()=>this.emitStatus(),"after-leave":()=>this.emitStatus()}},this.useVIfDirective?// v-if enabled, v-show is disabled inside directives under baseContentValues
this.isOpened?d:[]:// v-show is enabled inside directives under baseContentValues, v-if disabled
d)},handleClickOutside(){this.close()},checkIfClickedActivator(a){var b;if(this.disableClickOutside)return!1;if(!this.isOpened)return!1;const c=null===(b=this.clickOutsideWhitelist)||void 0===b?void 0:b.split(" ").filter(Boolean),d=null===c||void 0===c?void 0:c.map(a=>document.querySelector(a));for(let b=a.target;b;){var e;if([null===(e=this.opener)||void 0===e?void 0:e.elm,...d].filter(Boolean).includes(b))return!1;b=b.parentElement}return!0},detachContent(){this.$nextTick(()=>{this.position=this.calculatePosition();const a=document.getElementById("__layout"),b=this.$refs.content;a&&b&&a.appendChild(b)})},attachContent(){const a=this.$refs.container,b=this.$refs.content;a&&b&&a.appendChild(b)}},render(a){return a(this.tag,{ref:"container"},[this.genOpener(),this.genContent()])}});export default CmsAltMenu;